import { Controller } from "@hotwired/stimulus"
import * as CSRFTokenHelper from "../csrf_token_helper.js"

// Connects to data-controller="set-home-view-button"
export default class extends Controller {
  static values = {
    url: String,
    title: String,
    message: String,
    reset: Boolean
  }

  static targets = ['text']

  // Use initialize() instead of connect() because if the button is inside a datatable then JS causes it to be loaded
  // multiple times and it multiplies the clicks - clicking once produces 3 events

  // Wherever you put this button, make sure the potential new "home view" route's controller includes `HandleHomeView`
  // and that the specific route/action is listed like "home_view_rescue_record_not_found [:show]" (see CustomDashboardsController.rb)
  initialize() {
    const lifetimelyLogoLink = document.getElementById('navbar-lifetimely-logo')
    const lifetimelyMobileLogoLink = document.getElementById('navbar-lifetimely-mobile-logo-link')
    const button = this.element
    const textWrapper = this.textTarget
    const defaultRootPath = '/income_statement'

    button.addEventListener('click', () => {
      const customRootPath = this.urlValue
      const data = { custom_root_path: customRootPath }
      if (this.hasTitleValue) {
        data.title = this.titleValue
      }
      if (this.hasMessageValue) {
        data.message = this.messageValue
      }

      // Must get attribute value inside this event listener, in case it has been updated since first initialization
      const isResetButton = this.element.getAttribute('data-set-home-view-button-reset-value') === 'true'
      const postURL = (isResetButton) ? '/reset_home_view' : '/set_home_view'

      fetch(postURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Origin": "*",
          "X-CSRF-Token": CSRFTokenHelper.getCSRFToken()
        },
        body: JSON.stringify(data)
      }).then(res => {
        if (res.ok) {
          if (isResetButton) {
            lifetimelyLogoLink.href = defaultRootPath
            lifetimelyMobileLogoLink.href = defaultRootPath
            button.setAttribute('data-set-home-view-button-reset-value', false)
            textWrapper.innerText = 'Set as Home view'
          } else {
            lifetimelyLogoLink.href = customRootPath
            lifetimelyMobileLogoLink.href = customRootPath
            button.setAttribute('data-set-home-view-button-reset-value', true)
            textWrapper.innerText = 'Remove as Home view'
          }
        }
      });
    })
  }
}
