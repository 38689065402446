import { refreshTableCellData, initHoverCards } from './lifetime_value.js';

const initAddCohortButton = (cohortSelectContainerHTML, rowSkeletonHTML) => {
    $('button.add-another-cohort-link').click(function() {

        // Get filter to query for select option data
        const filterTypeParam = $('select[id=lifetime_compare_cohorts_select]').val();
        const filterPlaceholder = $('select[id=lifetime_compare_cohorts_select] option:selected').text();

        const cohortSelectContainer = $($.parseHTML(cohortSelectContainerHTML));

        if ($('.js-cohort-select-outer-container').length > 0) {
            $('.js-cohort-select-outer-container').last().after(cohortSelectContainer);
        } else {
            $("#cohort-selects-wrapper").prepend(cohortSelectContainer);
        }

        const cohortSelect = cohortSelectContainer.find('select.js-cohort-select');
        cohortSelect.select2({
            placeholder: filterPlaceholder,
            width: 'resolve',
            allowClear: false,
            closeOnSelect: true,
            containerCssClass: 'cohort-select',
            dropdownCssClass: 'cohort-select-dropdown',
            dataType: 'json',
            ajax: {
                url: `/filters?filter_type=${filterTypeParam}`,
                data: function (params) {
                    var query = {
                        search: params.term,
                        page: params.page || 1
                    };

                    // Query parameters will be ?search=[term]&page=[page]
                    return query;
                }
            },
        });

        // Set unique identifier, which links a given Select Tag with it's corresponding table row
        const uniqueIdentifier = Math.random();
        cohortSelect.attr('data-unique-identifier', uniqueIdentifier);

        // Comment this out if we don't want the select to open automatically after it is added.
        cohortSelect.select2('open');

        cohortSelect.on('change', function(e) {
            let data = {};
            data["timeframe"] = $('select[id=lifetime_timeframe_select]').val();
            const picker = $('input[name="ltv_daterange"]').data('daterangepicker');
            data["date_from"] = picker.startDate.format('YYYY-MM-DD');
            data["date_to"]= picker.endDate.format('YYYY-MM-DD');

            const cohortName = $(this).val();

            const rowSkeleton = $($.parseHTML(rowSkeletonHTML));
            rowSkeleton.attr('data-unique-identifier', uniqueIdentifier);

            let tableRow = $(`.compare-cohorts-table tbody tr[data-unique-identifier="${uniqueIdentifier}"]`);
            if (tableRow.length === 0) {
                // Inject skeleton row after last row
                $('.compare-cohorts-table tbody').append(rowSkeleton);
            } else {
                // Replace existing row with skeleton
                tableRow.replaceWith(rowSkeleton);
            }

            data["compare_cohorts"] = $('input[name="compare-cohorts-checkbox"]').is(':checked');
            data["cohort_name"] = cohortName;
            data["cohort_type"] = $('select[id="lifetime_compare_cohorts_select"]').val();

            $('.compare-cohorts-initial').hide();
            $('.compare-cohorts-table').show();

            $.post({
                url: $(this).attr('data-controller') + '/cohort-to-compare',
                data: data,
                success: function (dat, status, xhr) {
                    tableRow = $(`.compare-cohorts-table tbody tr[data-unique-identifier="${uniqueIdentifier}"]`);
                    const newTableRow = $($.parseHTML(dat));
                    newTableRow.attr('data-unique-identifier', uniqueIdentifier);
                    tableRow.replaceWith(newTableRow);

                    // Reset row indexes in case we add the spaghetti graph later.
                    resetRowIndexes();
                    // Reset number of header table cells to match number of body table cells.
                    resetTableHeader();

                    refreshTableCellData();
                    initHoverCards();
                }
            });
        });

        // Initialize removal button
        const removalButton = cohortSelectContainer.find('button.cohort-select-clear-btn')
        removalButton.on('click', function() {
            $(`.compare-cohorts-table tbody tr[data-unique-identifier="${uniqueIdentifier}"]`).remove();
            cohortSelectContainer.remove();

            showCompareCohortsPromptIfTableEmpty()
            resetRowIndexes();
        });
    });
}

const resetRowIndexes = () => {
    const rows = $('.compare-cohorts-table tbody tr');
    rows.each(function( index ) {
        // Removes classes that start with 'row'
        $(this).removeClass(function (index, css) {
            return (css.match (/\brow\S+/g) || []).join(' ');
        });

        $(this).addClass(`row${index}`);
    });
}

const resetTableHeader = () => {
    const numTimeintervals = parseInt($('.compare-cohorts-table tbody tr').first().attr('data-num-timeintervals'));
    const timeframeHeaderCols = $('.compare-cohorts-table thead th.js-timeinterval-colspan')
    timeframeHeaderCols.attr('colspan', numTimeintervals);
    const timeframe = $('select[id=lifetime_timeframe_select]').val();
    let timeframeHeaderText = ' since first order'

    switch (timeframe) {
        case "week":
            timeframeHeaderText = 'Weeks' + timeframeHeaderText;
            break;
        case "month":
            timeframeHeaderText = 'Months' + timeframeHeaderText;
            break;
        case "quarter":
            timeframeHeaderText = 'Calendar quarters' + timeframeHeaderText;
            break;
        case "year":
            timeframeHeaderText = 'Calendar quarters' + timeframeHeaderText;
            break;
        default:
            timeframeHeaderText = 'Months' + timeframeHeaderText;
    }

    timeframeHeaderCols.text(timeframeHeaderText);

    const desiredNumberOfTableHeaderCells = numTimeintervals + 1; // the +1 here accounts for the "First order" table column
    const actualNumberOfTableHeaderCells = $('.compare-cohorts-table thead tr').last().find('th').length;

    if (desiredNumberOfTableHeaderCells > actualNumberOfTableHeaderCells) {
        // Need to add missing header cells
        const appendedCells = []
        for (let i = actualNumberOfTableHeaderCells; i < desiredNumberOfTableHeaderCells; i++) {
            appendedCells.push(`<th scope=\"col\" class=\"tbl--center\">${i-1}</th>`);
        }
        $('.compare-cohorts-table thead tr').last().append( appendedCells.join('\n') );
    } else if (desiredNumberOfTableHeaderCells < actualNumberOfTableHeaderCells) {
        // Need to remove extra header cells
        const numberOfCellsToRemove = actualNumberOfTableHeaderCells - desiredNumberOfTableHeaderCells
        $('.compare-cohorts-table thead tr').last().find('th').slice(-numberOfCellsToRemove).remove();
    }
}

const updateControlsStates = () => {
    const metric = $('select[id=lifetime_select]').val();
    const compareCohortsCheckbox = $('input[name="compare-cohorts-checkbox"]');
    const compareCohortsSelect = $('select[id="lifetime_compare_cohorts_select"]');
    const compareCohortsLabel = $('#compare-cohorts');

    if ( ['customers_who_purchased_x_times','cac_sales_per_customer','cac_sales_margin_per_customer'].includes(metric) ) {
        compareCohortsCheckbox.prop( "disabled", true);
        compareCohortsLabel.addClass('disabled');
        compareCohortsSelect.prop( "disabled", true).addClass('disabled');
    } else {
        compareCohortsCheckbox.prop( "disabled", false);
        compareCohortsLabel.removeClass('disabled');
        compareCohortsSelect.prop( "disabled", false).removeClass('disabled');

        const ExportAndFilterButtons = $('#export-btn, .btn-filters')

        if ( compareCohortsCheckbox.is(':checked') ) {
            ExportAndFilterButtons.prop( "disabled", true).addClass('disabled');
        } else {
            ExportAndFilterButtons.prop( "disabled", false).removeClass('disabled');
        }
    }
}

const showCompareCohortsPromptIfTableEmpty = () => {
    const compareCohortsTableBody = $('.compare-cohorts-table tbody')
    if (compareCohortsTableBody.length > 0) {
        const compareCohortsTableBodyHTML = compareCohortsTableBody.html().trim();
        const compareCohortsTableHasContent = compareCohortsTableBodyHTML !== '';
        if (compareCohortsTableHasContent) {
            $('.compare-cohorts-initial').hide();
            $('.compare-cohorts-table').show();
        } else {
            $('.compare-cohorts-initial').show();
            $('.compare-cohorts-table').hide();
        }
    }
}

const triggerChangeAllCohortSelects = () => {
    const compareCohortSelects = $('select.js-cohort-select')
    compareCohortSelects.each(function () {
        const select = $(this)
        if (select.val() !== '') {
            select.trigger('change');
        }
    });
}


export {
    initAddCohortButton,
    updateControlsStates,
    showCompareCohortsPromptIfTableEmpty,
    triggerChangeAllCohortSelects
};
